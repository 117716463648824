import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useApiWithSessionExpiration } from "./useApiWithSessionExpiration";
import ClientTabs from "./ClientTabs";
import { formatInTimeZone } from "date-fns-tz";
import { parseISO } from "date-fns";
import TaskDetails from "./TaskDetails";
import Popup from "./Popup";
import "./CompletedReviews.css";

function CompletedReviews({ accessToken, apiBaseUrl, handleSessionExpiration, userData }) {
  const { id } = useParams();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [clientName, setClientName] = useState("");
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [sortBy, setSortBy] = useState("completedDate");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedTask, setSelectedTask] = useState(null);
  const limit = 50;

  const apiCall = useApiWithSessionExpiration(handleSessionExpiration);

  const fetchReviews = useMemo(
    () => async () => {
      if (isFirstLoad) {
        setIsFirstLoad(false);
        setIsLoading(true);
      }
      try {
        if (!selectedTask) {
          const response = await apiCall(`${apiBaseUrl}clients/${id}/completedreviews`, {
            method: "POST",
            accessToken: accessToken,
            data: {
              search: debouncedSearch,
              limit,
              offset,
              sortBy,
              sortOrder,
            },
          });
          setReviews(response.data.completedTasks);
          setTotalCount(response.data.totalCount);
          setClientName(response.data.client.name);
          setError(null);
        }
      } catch (err) {
        console.error("Error fetching completed reviews:", err);
        setError("Failed to fetch completed reviews. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    },
    [id, apiCall, apiBaseUrl, accessToken, debouncedSearch, offset, sortBy, sortOrder, selectedTask, isFirstLoad]
  );

  // Effect for debouncing search
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => clearTimeout(timer);
  }, [search]);

  // Effect for resetting offset when search or sort changes
  useEffect(() => {
    setOffset(0);
  }, [debouncedSearch, sortBy, sortOrder]);

  // Effect for fetching data
  useEffect(() => {
    fetchReviews();
  }, [fetchReviews]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortBy(column);
      setSortOrder("ASC");
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    return formatInTimeZone(parseISO(dateString), Intl.DateTimeFormat().resolvedOptions().timeZone, "MM/dd hh:mmaaa");
  };

  const handleTaskClick = (taskId) => {
    setSelectedTask(taskId);
  };

  const closePopup = () => {
    setSelectedTask(null);
  };

  const renderTable = () => {
    if (reviews.length === 0) {
      return <p className="no-results">No completed reviews to display.</p>;
    }

    const columns = [
      { label: "Marketing Piece", key: "title", width: "50%" },
      { label: "Created", key: "createdAt", width: "12%", isDate: true },
      { label: "Due", key: "dueDate", width: "12%", isDate: true },
      { label: "Completed", key: "completedDate", width: "12%", isDate: true },
      { label: "Assigned To", key: "assignedToName", width: "14%" },
    ];

    return (
      <table>
        <thead>
          <tr>
            {columns.map(({ label, key, width }) => (
              <th key={key} style={{ width }} onClick={() => handleSort(key)}>
                {label}
                {sortBy === key && <span className={`sort-indicator ${sortOrder.toLowerCase()}`} />}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {reviews.map((review) => (
            <tr key={review.id} onClick={() => handleTaskClick(review.id)} style={{ cursor: "pointer" }}>
              {columns.map(({ key, isDate }) => {
                const value = isDate ? formatDate(review[key]) : review[key];
                return (
                  <td key={`${review.id}-${key}`} title={value}>
                    {value}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalCount / limit);
    const currentPage = Math.floor(offset / limit) + 1;

    return (
      <div className="pagination">
        <button onClick={() => setOffset(Math.max(0, offset - limit))} disabled={offset === 0}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={() => setOffset(offset + limit)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    );
  };

  return (
    <div className="completed-reviews">
      <div className="client-tabs-container">
        <ClientTabs clientId={id} activeTab="completedreviews" />
      </div>
      <h2>Completed Reviews for {clientName}</h2>
      <div className="completed-reviews-content">
        <div className="search-bar">
          <input type="text" placeholder="Search reviews..." value={search} onChange={handleSearchChange} />
        </div>
        {isLoading ? (
          <div className="loading-container">
            <p>Loading reviews...</p>
          </div>
        ) : error ? (
          <div className="error-container">
            <p>{error}</p>
          </div>
        ) : (
          <>
            {renderTable()}
            {renderPagination()}
          </>
        )}
      </div>
      {selectedTask && (
        <Popup onClose={closePopup}>
          <TaskDetails
            accessToken={accessToken}
            apiBaseUrl={apiBaseUrl}
            handleSessionExpiration={handleSessionExpiration}
            taskId={selectedTask}
            isPopup={true}
            isAdmin={userData?.roles.includes("ADMIN") || false}
            onClose={closePopup}
            userData={userData}
          />
        </Popup>
      )}
    </div>
  );
}

export default CompletedReviews;
