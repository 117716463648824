import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { FaTrash, FaEdit, FaPlus, FaCheck, FaTimes, FaEye, FaEyeSlash } from "react-icons/fa";
import { Loader2 } from "lucide-react";
import Popup from "./Popup";
import StateMapping from "./StateMapping";
import "./Integrations.css";
import { useApiWithSessionExpiration } from "./useApiWithSessionExpiration";

const Integrations = ({ accessToken, apiBaseUrl, handleSessionExpiration }) => {
  const { id: clientId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [integrationConfigs, setIntegrationConfigs] = useState([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [configToDelete, setConfigToDelete] = useState(null);
  const [editingConfig, setEditingConfig] = useState(null);
  const [availableIntegrations, setAvailableIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [integrationConfigSettings, setIntegrationConfigSettings] = useState({});
  const [showClientSecret, setShowClientSecret] = useState(false);
  const [loadingBoardId, setLoadingBoardId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState(null);
  const apiCall = useApiWithSessionExpiration(handleSessionExpiration);

  const showToast = useCallback((message, type) => {
    setToast({ message, type });
    setTimeout(() => setToast(null), 3000);
  }, []);

  const handleWebhookAction = async (boardId, action) => {
    setLoadingBoardId(boardId);
    try {
      if (action === "add") {
        const response = await apiCall(`${apiBaseUrl}integrationboardwebhooks`, {
          method: "POST",
          accessToken: accessToken,
          data: { boardId },
        });

        const responseData = response.data || response;
        const configuredEvents = responseData.webhooks.map((w) => w.eventType);
        const missingEvents = responseData.events.filter((e) => !configuredEvents.includes(e));

        setEditingConfig((prevConfig) => ({
          ...prevConfig,
          boards: prevConfig.boards.map((board) => {
            if (board.id === boardId) {
              return {
                ...board,
                webhooks: responseData.webhooks,
              };
            }
            return board;
          }),
        }));

        if (missingEvents.length > 0) {
          showToast(
            `${configuredEvents.length} webhooks successfully configured; ${missingEvents.join(", ")} not configured`,
            "warning"
          );
        } else {
          showToast("All webhooks successfully configured", "success");
        }
      } else {
        const response = await apiCall(`${apiBaseUrl}integrationboardwebhooks?boardId=${boardId}`, {
          method: "DELETE",
          accessToken: accessToken,
        });

        const message = response.data || "Webhooks successfully deleted";
        showToast(message, "success");

        setEditingConfig((prevConfig) => ({
          ...prevConfig,
          boards: prevConfig.boards.map((board) => {
            if (board.id === boardId) {
              return {
                ...board,
                webhooks: [],
              };
            }
            return board;
          }),
        }));
      }

      await fetchIntegrationConfigs();
    } catch (err) {
      console.error(`Error ${action}ing webhooks:`, err);
      showToast(`Unable to ${action} webhooks`, "error");
    } finally {
      setLoadingBoardId(null);
    }
  };

  const getBoardStats = (config) => {
    const totalBoards = config.boards.length;
    const monitoredBoards = config.boards.filter(
      (board) => board.webhooks && board.webhooks.some((webhook) => webhook.isEnabled)
    ).length;
    return { totalBoards, monitoredBoards };
  };

  const fetchIntegrationConfigs = useCallback(async () => {
    try {
      const response = await apiCall(`${apiBaseUrl}integrationconfigs?clientId=${clientId}`, {
        method: "GET",
        accessToken: accessToken,
      });
      setIntegrationConfigs(response.data);
      setError(null);
    } catch (err) {
      console.error("Error fetching integration configs:", err);
      setError("Failed to fetch integrations. Please try again later.");
    }
  }, [apiCall, apiBaseUrl, accessToken, clientId]);

  // Handle OAuth callback messages
  useEffect(() => {
    if (location.state?.integrationSuccess || location.state?.integrationError) {
      // Show the toast
      showToast(location.state.message, location.state.integrationSuccess ? "success" : "error");

      // If successful, refresh the configs
      if (location.state.integrationSuccess) {
        fetchIntegrationConfigs();
      }

      // Clear the location state to prevent showing the message again on refresh
      navigate(location.pathname, { replace: true });
    }
  }, [location, showToast, fetchIntegrationConfigs, navigate]);

  const fetchAvailableIntegrations = useCallback(async () => {
    try {
      const response = await apiCall(`${apiBaseUrl}integrationoptions`, {
        method: "GET",
        accessToken: accessToken,
      });
      setAvailableIntegrations(response.data);
    } catch (err) {
      console.error("Error fetching available integrations:", err);
    }
  }, [apiCall, apiBaseUrl, accessToken]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await fetchIntegrationConfigs();
      await fetchAvailableIntegrations();
      setIsLoading(false);
    };
    loadData();
  }, [fetchIntegrationConfigs, fetchAvailableIntegrations]);

  const handleDeleteClick = (config) => {
    setConfigToDelete(config);
    setShowDeleteDialog(true);
  };

  useEffect(() => {
    if (editingConfig?.integration.integrationType === "REDOAK") {
      setIntegrationConfigSettings(editingConfig.settings || {});
    }
  }, [editingConfig]);

  const handleConfirmDelete = async () => {
    try {
      await apiCall(`${apiBaseUrl}integrationconfigs/${configToDelete.id}`, {
        method: "DELETE",
        accessToken: accessToken,
      });
      fetchIntegrationConfigs();
      setShowDeleteDialog(false);
      setConfigToDelete(null);
      showToast("Integration deleted successfully", "success");
    } catch (err) {
      console.error("Error deleting integration:", err);
      showToast("Failed to delete integration", "error");
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
    setConfigToDelete(null);
  };

  const handleAdd = async () => {
    try {
      const response = await apiCall(`${apiBaseUrl}integrationconfigs`, {
        method: "POST",
        accessToken: accessToken,
        data: {
          integrationId: selectedIntegration,
          clientId: clientId,
          settings: integrationConfigSettings,
        },
      });
      const newConfig = response.data;

      if (newConfig.integration.integrationType === "MONDAY") {
        const redirectUri = `${process.env.REACT_APP_ROOT}/oauth/callback`;
        const stateParam = btoa(
          JSON.stringify({
            configId: newConfig.id,
            clientId: clientId,
            redirectUri: redirectUri,
            apiBaseUrl: apiBaseUrl,
          })
        );
        window.location.href = `${newConfig.integration.authUrl}?client_id=${newConfig.integration.clientId}&redirect_uri=${process.env.REACT_APP_ROOT}/oauth/callback&state=${stateParam}`;
      } else {
        fetchIntegrationConfigs();
        setShowAddDialog(false);
        showToast("Integration added successfully", "success");
      }
    } catch (err) {
      console.error("Error adding integration:", err);
      showToast("Failed to add integration", "error");
    }
  };

  const handleSettingChange = (key, value) => {
    if (key === "tenantId") {
      value = value.toUpperCase().replace(/[^A-Z0-9]/g, "");
    }
    setIntegrationConfigSettings((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleAuthenticate = (config) => {
    const redirectUri = `${process.env.REACT_APP_ROOT}/oauth/callback`;
    // Create state parameter containing both configId and clientId
    const stateParam = btoa(
      JSON.stringify({
        configId: config.id,
        clientId: clientId,
        redirectUri: redirectUri,
        apiBaseUrl: apiBaseUrl,
      })
    );

    // Redirect to OAuth authorization URL with state parameter
    window.location.href = `${config.integration.authUrl}?client_id=${config.integration.clientId}&redirect_uri=${process.env.REACT_APP_ROOT}/oauth/callback&state=${stateParam}`;
  };

  if (isLoading) {
    return <div className="integrations-loading">Loading integrations...</div>;
  }

  const handleSaveEdit = async () => {
    try {
      await apiCall(`${apiBaseUrl}integrationconfigs/${editingConfig.id}`, {
        method: "PUT",
        accessToken: accessToken,
        data: {
          settings: integrationConfigSettings,
        },
      });
      fetchIntegrationConfigs();
      setShowEditDialog(false);
      showToast("Integration updated successfully", "success");
    } catch (err) {
      console.error("Error updating integration:", err);
      showToast("Failed to update integration", "error");
    }
  };

  const renderEditDialog = () => {
    if (!editingConfig) return null;

    const isRedOak = editingConfig.integration.integrationType === "REDOAK";

    return (
      <Popup onClose={() => setShowEditDialog(false)} minHeight="300px">
        <h3>Edit Integration: {editingConfig.integration.name}</h3>
        <div className="integration-form">
          {editingConfig.integration.description && (
            <div className="description">
              {editingConfig.integration.description.split("\n").map((line, i) => (
                <p key={i}>{line}</p>
              ))}
            </div>
          )}

          {isRedOak ? (
            <div className="redoak-form">
              <div className="form-group">
                <label htmlFor="tenantId">Tenant ID</label>
                <input
                  id="tenantId"
                  type="text"
                  value={integrationConfigSettings.tenantId || ""}
                  onChange={(e) => handleSettingChange("tenantId", e.target.value)}
                  placeholder="Enter Tenant ID"
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label htmlFor="clientId">Client ID</label>
                <input
                  id="clientId"
                  type="text"
                  value={integrationConfigSettings.clientId || ""}
                  onChange={(e) => handleSettingChange("clientId", e.target.value)}
                  placeholder="Enter Client ID"
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label htmlFor="descriptionQuestion">Description Question</label>
                <input
                  id="descriptionQuestion"
                  type="text"
                  value={integrationConfigSettings.descriptionQuestion || ""}
                  onChange={(e) => handleSettingChange("descriptionQuestion", e.target.value)}
                  placeholder="In Red Oak, what is the submission question prompt we should use to find the task description?"
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label>State Mapping</label>
                <StateMapping
                  stateMapping={
                    integrationConfigSettings.stateMapping || {
                      CANCELLED: [],
                      COMPLETED: [],
                      IN_PROGRESS: [],
                      PENDING_CLIENT: [],
                    }
                  }
                  onChange={(newMapping) => handleSettingChange("stateMapping", newMapping)}
                />
              </div>

              <button onClick={handleSaveEdit} className="save-button">
                Save Changes
              </button>
            </div>
          ) : (
            <>
              {editingConfig.integration.scopes && editingConfig.integration.scopes.length > 0 && (
                <div className="scopes">
                  <h4>Required Permissions:</h4>
                  <ul>
                    {editingConfig.integration.scopes.map((scope, index) => (
                      <li key={index}>{scope}</li>
                    ))}
                  </ul>
                </div>
              )}

              <h4>Webhooks</h4>
              <table className="board-webhooks-table">
                <thead>
                  <tr>
                    <th className="board-name-column">Board</th>
                    <th>Webhooks</th>
                    <th className="webhooks-table-actions">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {editingConfig.boards.map((board) => {
                    const hasWebhooks = board.webhooks && board.webhooks.some((w) => w.isEnabled);
                    const enabledWebhooks =
                      board.webhooks
                        ?.filter((w) => w.isEnabled)
                        .map((w) => w.eventType)
                        .join(", ") || "None";
                    const isLoading = loadingBoardId === board.id;

                    return (
                      <tr key={board.id}>
                        <td>{board.name}</td>
                        <td>{enabledWebhooks}</td>
                        <td>
                          <button
                            onClick={() => handleWebhookAction(board.id, hasWebhooks ? "delete" : "add")}
                            className={`${hasWebhooks ? "delete-webhooks-button" : "add-webhooks-button"} ${
                              isLoading ? "loading" : ""
                            }`}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <Loader2 className="spin-icon" />
                                {hasWebhooks ? "Deleting..." : "Adding..."}
                              </>
                            ) : hasWebhooks ? (
                              "Delete"
                            ) : (
                              "Add"
                            )}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {!editingConfig.hasCredentials && (
                <button onClick={() => handleAuthenticate(editingConfig)} className="authenticate-button">
                  Authenticate
                </button>
              )}
            </>
          )}
        </div>
      </Popup>
    );
  };

  if (isLoading) {
    return <div className="integrations-loading">Loading integrations...</div>;
  }

  if (error) {
    return <div className="integrations-error">{error}</div>;
  }

  return (
    <div className="integrations-container">
      <h3>Integrations</h3>
      <div className="table-container">
        <button onClick={() => setShowAddDialog(true)} className="add-integration-button">
          <FaPlus /> Add Integration
        </button>
        <table className="integrations-table">
          <thead>
            <tr>
              <th>Name</th>
              <th className="credentials-column">Boards</th>
              <th className="credentials-column">Monitored Boards</th>
              <th className="credentials-column">Valid Credentials</th>
              <th>Created By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {integrationConfigs.map((config) => {
              const { totalBoards, monitoredBoards } = getBoardStats(config);
              return (
                <tr key={config.id}>
                  <td>{config.integration.name}</td>
                  <td className="credentials-column">{totalBoards}</td>
                  <td className="credentials-column">{monitoredBoards}</td>
                  <td className="credentials-column">
                    {config.hasCredentials ? <FaCheck className="check-icon" /> : <FaTimes className="x-icon" />}
                  </td>
                  <td>{config.createdBy?.displayName || "Unknown"}</td>
                  <td className="center-align">
                    <button
                      onClick={() => {
                        setEditingConfig(config);
                        setShowEditDialog(true);
                      }}
                      className="edit-button"
                    >
                      <FaEdit />
                    </button>
                    <button onClick={() => handleDeleteClick(config)} className="delete-button">
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {showAddDialog && (
        <Popup onClose={() => setShowAddDialog(false)} minHeight="300px">
          <h3>Add Integration</h3>
          <div className="integration-form">
            <select
              value={selectedIntegration}
              onChange={(e) => {
                const newIntegration = e.target.value;
                setSelectedIntegration(newIntegration);

                // Reset settings when integration changes
                setIntegrationConfigSettings({});

                // Initialize stateMapping if REDOAK is selected
                if (availableIntegrations.find((i) => i.id === newIntegration)?.integrationType === "REDOAK") {
                  setIntegrationConfigSettings({
                    stateMapping: {
                      CANCELLED: [],
                      COMPLETED: [],
                      IN_PROGRESS: [],
                      PENDING_CLIENT: [],
                    },
                  });
                }
              }}
              className="integration-select"
            >
              <option value="">Select an integration</option>
              {availableIntegrations
                .filter((integration) => !integrationConfigs.some((config) => config.integration.id === integration.id))
                .map((integration) => (
                  <option key={integration.id} value={integration.id}>
                    {integration.name}
                  </option>
                ))}
            </select>

            {selectedIntegration && (
              <div className="integration-details">
                {(availableIntegrations.find((i) => i.id === selectedIntegration)?.description || "")
                  .split("\n")
                  .map((line, i) => (
                    <p key={i}>{line}</p>
                  ))}
              </div>
            )}

            {selectedIntegration &&
              availableIntegrations.find((i) => i.id === selectedIntegration)?.integrationType === "REDOAK" && (
                <div className="redoak-form">
                  <div className="form-group">
                    <label htmlFor="tenantId">Tenant ID</label>
                    <input
                      id="tenantId"
                      type="text"
                      value={integrationConfigSettings.tenantId}
                      onChange={(e) => handleSettingChange("tenantId", e.target.value)}
                      placeholder="Enter Tenant ID"
                      className="form-input"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="clientId">Client ID</label>
                    <input
                      id="clientId"
                      type="text"
                      value={integrationConfigSettings.clientId}
                      onChange={(e) => handleSettingChange("clientId", e.target.value)}
                      placeholder="Enter Client ID"
                      className="form-input"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="clientSecret">Client Secret</label>
                    <div className="secret-input-container">
                      <input
                        id="clientSecret"
                        type={showClientSecret ? "text" : "password"}
                        value={integrationConfigSettings.clientSecret}
                        onChange={(e) => handleSettingChange("clientSecret", e.target.value)}
                        placeholder="Enter Client Secret"
                        className="form-input"
                      />
                      <button
                        type="button"
                        onClick={() => setShowClientSecret(!showClientSecret)}
                        className="toggle-secret-button"
                      >
                        {showClientSecret ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="descriptionQuestion">Description Question</label>
                    <input
                      id="descriptionQuestion"
                      type="text"
                      value={integrationConfigSettings.descriptionQuestion}
                      onChange={(e) => handleSettingChange("descriptionQuestion", e.target.value)}
                      placeholder="In Red Oak, what is the submission question prompt we should use to find the task description?"
                      className="form-input"
                    />
                  </div>

                  <div className="form-group">
                    <label>State Mapping</label>
                    <StateMapping
                      stateMapping={integrationConfigSettings.stateMapping}
                      onChange={(newMapping) => handleSettingChange("stateMapping", newMapping)}
                    />
                  </div>
                </div>
              )}

            {selectedIntegration && (
              <button onClick={handleAdd} className="add-button">
                Add Integration
              </button>
            )}
          </div>
        </Popup>
      )}

      {showEditDialog && renderEditDialog()}

      {showDeleteDialog && configToDelete && (
        <Popup onClose={handleCancelDelete} minHeight="180px">
          <div className="delete-integration-confirmation-dialog">
            <p>Are you sure you want to delete the {configToDelete.integration.name} integration?</p>
            <div className="delete-integration-button-group">
              <button onClick={handleConfirmDelete}>Yes</button>
              <button onClick={handleCancelDelete}>No</button>
            </div>
          </div>
        </Popup>
      )}

      {toast && <div className={`toast ${toast.type}`}>{toast.message}</div>}
    </div>
  );
};

export default Integrations;
