import React, { useState, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa";

const TypeFilter = ({ tasks, onFilterChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    const uniqueTypes = [...new Set(tasks.map((task) => task.type))].filter(Boolean).sort((a, b) => a.localeCompare(b));
    setTypes(uniqueTypes);
  }, [tasks]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleTypeChange = (type) => {
    setSelectedTypes((prev) => {
      const newSelection = prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type];

      onFilterChange(newSelection);
      return newSelection;
    });
  };

  return (
    <div className="type-filter">
      <button onClick={handleToggle} className="filter-button">
        <span className="filter-button-text">
          {selectedTypes.length > 0
            ? `${selectedTypes.length} Type${selectedTypes.length > 1 ? "s" : ""} Selected`
            : "No Type Filter"}
        </span>
        <span className="filter-button-icon">
          <FaChevronDown />
        </span>
      </button>
      {isOpen && (
        <div className="type-list">
          {types.map((type) => (
            <label key={type} className="type-item">
              <input type="checkbox" checked={selectedTypes.includes(type)} onChange={() => handleTypeChange(type)} />
              <span>{type}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default TypeFilter;
