import React from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import "./StateMapping.css";

const StateMapping = ({ stateMapping, onChange }) => {
  const states = {
    CANCELLED: "Cancelled",
    COMPLETED: "Completed",
    IN_PROGRESS: "In Progress",
    PENDING_CLIENT: "Pending Client",
  };

  const handleAddMapping = (state) => {
    const newMapping = {
      ...stateMapping,
      [state]: [...(stateMapping[state] || []), ""],
    };
    onChange(newMapping);
  };

  const handleRemoveMapping = (state, index) => {
    const newMapping = {
      ...stateMapping,
      [state]: stateMapping[state].filter((_, i) => i !== index),
    };
    onChange(newMapping);
  };

  const handleUpdateMapping = (state, index, value) => {
    const newMapping = {
      ...stateMapping,
      [state]: stateMapping[state].map((item, i) => (i === index ? value : item)),
    };
    onChange(newMapping);
  };

  return (
    <div className="state-mapping-container">
      {Object.entries(states).map(([stateKey, stateLabel]) => (
        <div key={stateKey} className="state-mapping-section">
          <div className="state-mapping-header">
            <label className="state-mapping-label">{stateLabel} States</label>
            <button type="button" onClick={() => handleAddMapping(stateKey)} className="add-mapping-button">
              <FaPlus className="button-icon" />
              Add Mapping
            </button>
          </div>

          <div className="mapping-list">
            {(stateMapping[stateKey] || []).map((value, index) => (
              <div key={index} className="mapping-item">
                <input
                  type="text"
                  value={value}
                  onChange={(e) => handleUpdateMapping(stateKey, index, e.target.value)}
                  className="mapping-input"
                  placeholder={`Enter ${stateLabel.toLowerCase()} state...`}
                />
                <button
                  type="button"
                  onClick={() => handleRemoveMapping(stateKey, index)}
                  className="remove-mapping-button"
                >
                  <FaTrash />
                </button>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StateMapping;
